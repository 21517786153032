<template>
<div v-if="isMobile" class="">  
    <div class="my-6">
    <h3 class="font-bold">Lista de {{ route.name }} de Usuários</h3>
    <span class="text-sm text-gray-500"
      >Aqui você pode observar todos os itens relacionados aos vínculos de usuários como
      suas respectivas ações.
    </span>
  </div>
  <div class="mb-6 justify-end">
    <div class="flex-1 mr-3">
    <Input 
      type="default"
      v-model="usuario.email"
      label="Usuário / Evento"
      placeholder="Usuário / Evento"
    />
    </div>
    <div class="inline-block mr-3 ml-3">
      <Button @click="searchUser" color="primary" text="Pesquisar" />
    </div>
    <div class="inline-block mr-3 ml-3">
      <router-link to="/vinculos/criar">
        <Button color="primary" text="Criar novo vínculo" />
      </router-link>
    </div>
  </div>
  <div class="card">
    <DataTable
      :headers="headers"
      :data="data"
      :options="options"
      :exports="true"
      :mobile="isMobile"
      color="primary"
    />
  </div>
</div>

<div v-else>
    <div class="my-6">
    <h3 class="font-bold">Lista de {{ route.name }} de Usuários</h3>
    <span class="text-sm text-gray-500"
      >Aqui você pode observar todos os itens relacionados aos vínculos de usuários como
      suas respectivas ações.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <div class="flex-1 mr-3">
    <Input 
      type="default"
      v-model="usuario.email"
      label="Usuário / Evento"
      placeholder="Usuário / Evento"
    />
    </div>
    <div class="flex-1 mr-3">
      <Button @click="searchUser" color="primary" text="Pesquisar" />
    </div>
    <router-link to="/vinculos/criar">
      <Button color="primary" text="Criar novo vínculo" />
    </router-link>
  </div>
  <div class="card">
    <DataTable
      :headers="headers"
      :data="data"
      :options="options"
      :exports="true"
      color="primary"
    />
  </div>
</div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute } from "vue-router";
import Input from "@/components/Elements/Input.vue";

export default {
  name: "VinculosUsuarios",
  components: {
    DataTable,
    Button,
    Input
},
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Nome", value: "nome" },
      { key: "Tipo", value: "tipo" },
      { key: "Evento", value: "evento" },
      { key: "Ações", value: "" },
    ];

    const route = useRoute();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);
    const usuario = ref({
      "email": ""
    })

    onMounted(async () => {
      /*try {
        loader.open();
        data.value = await GET("usuarios_vinculos");
        loader.close();
      } catch (e) {
        loader.close();
        alert.open("Erro", e, "danger");
      }*/
    });
    
    const searchUser = async ()=>{
      loader.open();
      const email = usuario.value.email
      try{
        data.value = await GET(`usuarios_vinculos/${email}/usuarios`)

        if(data.value.length == 0)
        {
           alert.open("Não há vinculos relacionado a esse usuário.")
        }

      }catch(e){
        alert.open("Erro ao buscar usuário.")
      }
      loader.close();
    };

    const deleteItem = (item) => {
      loader.open();
      const deleteFunction = async function() {
        try {
          loader.open();
          const indexItem = data.value.indexOf(item)
          await DELETE(`usuarios_vinculos/${item.id}`);
          data.value.splice(indexItem, 1)
          
          loader.close();
          alert.open("Sucesso!", `Vínculo deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse vínculo?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const options = [
      { icon: "trash", action: (param) => deleteItem(param), title: "Excluir vínculo" },
    ];

    return { headers, data, options, route, searchUser, usuario };
  },
};
</script>

<style></style>
